import logo from './logo.png';
// import apk from './app-release.apk';
import './App.css';
import { useEffect, useState } from 'react';

const App = () => {

  const [isJapan, setIsJapan] = useState(false);

  useEffect(() => {
    const app = async () => {
      const data = await fetch('https://api-geolocation.zeit.sh/')
      const result = await data.json();

      const country = result.country.toLocaleLowerCase();

      if (country === 'japan') {
          setIsJapan(true);
      } else if (country === 'jepang') {
          setIsJapan(true);
      } else {
          setIsJapan(false);
      }

      return () => {

      }

    }

    app();
  }, [])

  return (
      <div className="app">
          <div className='body-app'>
              <img className='app-playstore-logo' src={logo} />
              <a className='app-playstore' href='https://play.google.com/store/apps/details?id=com.lovehug&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img className='app-playstore-logo' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
              </a>
              {/* <a href={apk} style={{display:'none'}}>
                 <img className='app-playstore-logo' alt='Get it on Apk' src='https://www.yt3dl.net/images/apk-download-badge.png' />
               </a> */}
              <a href='https://kutt.it/register-lovehug' style={{ textDecoration:'none', color:'#fff' }}>
                <div className='app-create'>create account</div>
              </a>
              <div className='app-box'>
                {isJapan ? (
                  <p className='app-info'>
                   以前にプレイストア上のアプリケーションにアクセスできなかったユーザーの場合、
                   今、それは使用することができます、プレイストア上の新しいアプリケーションをダウンロードし、ログインしてください、
                   すでにwelovemanga.oneのアカウントを持っている人のために
                   そうでない場合は、アカウントの作成をクリックしてください, 登録のため、
                   アプリ内アカウントは機能せず、その後アプリを再起動します
                 </p>
                ) : (
                  <p className='app-info'>
                    don't register in the application, because it doesn't work, please click create an account, follow the instructions, and login to the apps with the account you just created, if you successfully login please restart your apps
                  </p>
                )}
              </div>
              <p style={{ fontSize:12 }}>Translate by bing</p>
          </div>
      </div>
  );
}

export default App;
